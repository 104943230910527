import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'Index',
  key: '',
  exact: true,
  importPath: 'index'
}, {
  name: 'Covid Testing',
  exact: true,
  key: 'schedule-covid'
}, {
  name: 'Covid Testing',
  exact: true,
  key: 'schedule',
  redirect: '/schedule-covid'
}, {
  name: 'Sexually Transmitted Infections Testing',
  exact: true,
  key: 'sexually-transmitted-infections-testing'
}, {
  name: 'FAQs',
  exact: true,
  key: 'faqs'
}, {
  name: 'Group / Event Testing',
  exact: true,
  key: 'business-inquires'
}, {
  name: 'At Home Antigen Test Kits',
  exact: true,
  key: 'antigen'
}, {
  name: 'Schedule as appointment - UWS',
  key: 'uws',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Chelsea',
  key: 'chelsea',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Noho',
  key: 'noho',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - midtown',
  key: 'midtown',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Astoria',
  key: 'astoria',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Chinatown',
  key: 'chinatown',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - UES',
  key: 'ues',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Harlem Medical Center',
  key: 'harlem-medical-center',
  exact: true,
  importPath: 'getappointment'
}, {
  name: 'Schedule as appointment - Concierge',
  key: 'concierge',
  exact: true,
  importPath: 'getappointment'
} // },
// {
//   name: 'menu.dashboard',
//   key: 'dashboard',
//   children: [
//     {
//       name: 'menu.dashboard.workplace',
//       key: 'dashboard/workplace',
//     },
//   ],
// },
// {
//   name: 'Example',
//   key: 'p/:articleid',
//   importPath: 'p',
// },
// {
//   name: 'Example',
//   key: 'example',
// },
];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [userPermission]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;