const i18n = {
  'en-US': {
    'menu.dashboard': 'Dashboard',
    'menu.dashboard.workplace': 'Workplace',
    'menu.user.info': 'User Info',
    'menu.user.setting': 'User Setting',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeek': 'Color Week',
    'settings.alertContent': 'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message': 'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip': '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language changed to ',
    'navbar.search.placeholder': 'Search for content'
  },
  'pl-PL': {
    'menu.dashboard': 'Panel',
    'menu.dashboard.workplace': 'Workplace',
    'menu.user.info': 'Info o użytkowniku',
    'menu.user.setting': 'Ustawienia',
    'menu.user.switchRoles': 'Zamiana ról',
    'menu.user.role.admin': 'Administrator',
    'menu.user.role.user': 'Standardowy',
    'navbar.logout': 'Wyloguj',
    'settings.title': 'Ustawienia',
    'settings.themeColor': 'Kolor motywu',
    'settings.content': 'Ustawienia kontentu',
    'settings.navbar': 'Pasek nawigacyjny',
    'settings.menuWidth': 'Szerokość Menu (px)',
    'settings.navbar.theme.toLight': 'Naciśnij, by użyć jasnego trybu',
    'settings.navbar.theme.toDark': 'Naciśnij, by użyć ciemnego trybu',
    'settings.menu': 'Menu',
    'settings.footer': 'Stopka',
    'settings.otherSettings': 'Inne Ustawienia',
    'settings.colorWeek': 'Kolor tygodnia',
    'settings.alertContent': 'Ponieważ konfiguracja działa tylko tymczasowo, jeśli chcesz naprawdę wpłynąć na projekt, kliknij przycisk "Kopiuj ustawienia" poniżej i zastąp konfigurację w pliku settings.json.',
    'settings.copySettings': 'Skopiuj Ustawienia',
    'settings.copySettings.message': 'Kopiowanie powiodło się, proszę wklej to do pliku src/settings.json.',
    'settings.close': 'Zamknij',
    'settings.color.tooltip': '10 kolorów gradientu generowanych zgodnie z kolorem motywu',
    'message.tab.title.message': 'Wiadomość',
    'message.tab.title.notice': 'Powiadomienie',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'Wszystko przeczytane',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Puste',
    'message.empty.tips': 'Brak Kontentu',
    'message.lang.tips': 'Zmieniono język na ',
    'navbar.search.placeholder': 'Poszukaj kontentu'
  }
};
export default i18n;